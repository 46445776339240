import React from "react"
import { MarketingFeatures } from "../../../../../features/marketing/components/MarketingFeatures"
import { toMarketingFeatures } from "../../../converters/marketingFeatures"

interface Props {
  content: any
}

const MarketingFeaturesWrapper = ({ content }: Props) => {
  return (
    <MarketingFeatures {...toMarketingFeatures(content.item.featuresBox)} />
  )
}

export default MarketingFeaturesWrapper

import React from "react"
import classes from "./FeaturesBox.module.css"
import { CustomCol, CustomRow } from "../../spacings"
import Typography from "../../atoms/Typography"

export interface FeaturesBoxItem {
  icon: React.ReactNode
  title: React.ReactNode
  subtitle?: React.ReactNode
}

export interface FeaturesBoxProps {
  title: React.ReactNode
  items: FeaturesBoxItem[]
}

const FeaturesBox = ({ title, items }: FeaturesBoxProps) => {
  return (
    <div>
      <div className="mb-12">
        <Typography variant="h5" textClassName={classes.title} component="div">
          {title}
        </Typography>
      </div>
      <CustomRow className={classes.container}>
        {items.map((item, index) => (
          <CustomCol xs={12} md={3} key={index} className={classes.column}>
            <div className={classes.item}>
              <div className={classes.icon}>
                {item.icon}
                <Typography
                  variant="body1"
                  weight="bold"
                  textClassName={classes.itemTitle}
                  component="div"
                >
                  {item.title}
                </Typography>
              </div>
              <div>
                {item.subtitle && (
                  <div className="mt-1">
                    <Typography variant="body1" weight="light" component="div">
                      {item.subtitle}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </CustomCol>
        ))}
      </CustomRow>
    </div>
  )
}

export default FeaturesBox

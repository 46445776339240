import React from "react"
import { SanityNextImg } from "../../../cms/components"
import {
  MarketingFeatureItem,
  MarketingFeaturesProps,
} from "../../../features/marketing/components/MarketingFeatures"
import { TwRichTextField } from "../../../root/punks/core/fields/RichTextField"

const toMarketingFeaturesItem = (item: any): MarketingFeatureItem => ({
  icon: (
    <SanityNextImg
      asset={item.icon?.asset}
      width={40}
      layout="intrinsic"
      noBox
      alt={item.seoTitle}
    />
  ),
  title: <TwRichTextField value={item.title} variant="body1" />,
  subtitle: <TwRichTextField value={item.subtitle} variant="subtitle2" />,
})

export const toMarketingFeatures = (item: any): MarketingFeaturesProps => ({
  backgroundColor: item.data.styles.backgroundColor,
  color: item.data.styles?.color,
  title: item.data.contents?.title,
  items: item.data.contents?.items.map(toMarketingFeaturesItem),
})

import React from "react"
import FeaturesBox from "../../../../ui/organisms/FeaturesBox"
import { CustomBox } from "../../../../ui/spacings"
import { ThemeColor } from "@punks/core"

export interface MarketingFeatureItem {
  icon: React.ReactNode
  title: React.ReactNode
  subtitle: React.ReactNode
}

export interface MarketingFeaturesProps {
  backgroundColor?: ThemeColor
  color?: ThemeColor
  title: React.ReactNode
  items: MarketingFeatureItem[]
}

export const MarketingFeatures = ({
  color,
  title,
  items,
}: MarketingFeaturesProps) => {
  return (
    <CustomBox
      className="pt-10 my-4 pb:8 sm:pb-14"
      background="disabled"
      color={color}
    >
      <FeaturesBox title={title} items={items} />
    </CustomBox>
  )
}
